<template>
    <div class="form-container">
        <div class="mb-4">
            <h1 class="mb-3">Verify your account</h1>
            <p v-if="verified">Verified Successfully!</p>
            <p v-else>You account is currently being verified....</p>
        </div>

        <b-alert v-if="error" class="mt-4" variant="danger" show dismissible>
            {{ error }}
        </b-alert>
        <div class="form-group m-mt-40">
            <b-button block variant="primary" :class="classObject" :disabled="btnDisabled" @click="signIn()">
                {{ processing? "": "Continue" }}
                <RingLoader v-if="processing" />
            </b-button>
        </div>
    </div>
</template>
<script>
import RingLoader from "@/components/loader/RingLoader";

export default {
    title: "Menaget - Client Dashboard Verify Page",
    components: { RingLoader },
    data() {
        return {
            error: "",
            verified: true,
            processing: false
        };
    },
    computed: {
        btnDisabled() {
            return this.verified === false
        },
        passwordType() {
            return this.visiblePass ? "text" : "password";
        },
    },
    mounted() {
        this.verifyAccount()
    },
    methods: {
        verifyAccount() {
            this.$store
                .dispatch("auth/verifyAccount", {
                    token: this.$route.query.token
                })
                .then(() => {
                    this.verified = true
                })
                .catch((err) => {
                    let data = err?.response;
                    if (data?.status != 200) {
                        this.error = data.data.message;
                    }
                })
        },
        signIn() {
            this.processing = true
            this.$router.push("/login");
        },
    },
};
</script>
